<template lang="pug">
  .container-fluid
    .container
      .header.text-center FAQ<br><small>よくあるご質問</small>

      .row.justify-content-md-center.navi-row
        .col-12.col-md-4
          b-button.faq(@click="scrollCard('about')" block="" variant="outline-info" size="sm") ヤマラボについて
        .col-12.col-md-4
          b-button.faq(@click="scrollCard('function')" block="" variant="outline-info" size="sm") 機能について
      .row.justify-content-md-center.navi-row
        .col-12.col-md-4
          b-button.faq(@click="scrollCard('premium')" block="" variant="outline-info" size="sm") 有料プランについて
        .col-12.col-md-4
          b-button.faq(@click="scrollCard('inquiry')" block="" variant="outline-info" size="sm") その他

      .row.justify-content-md-center
        .col-12.col-md-8
          .card#about
            .card-body
              h5.card-title.text-left ヤマラボ(YamaLab.)について
              p.card-text.text-left.question ヤマラボとは何ですか？
              p.card-text.text-left.answer ヤマラボは<b-link to="/info/company">株式会社ロビンソン</b-link>が運営する外岩クライミング情報サイトです。
              p.card-text.text-left.question ヤマラボはアプリですか？
              p.card-text.text-left.answer ヤマラボはスマートフォンアプリではございません。PC、タブレット、スマートフォン端末のWEBブラウザからご利用いただけます。
              p.card-text.text-left.question 無料で使えますか？
              p.card-text.text-left.answer ユーザー登録なしで全国の約200岩場を検索できます。有料プランにご契約頂きますと「岩場のルート一覧」や「画像トポ」の参照、「記録の作成」が可能になります。詳しくは
                <b-link to="/info/user_guide">こちら</b-link>もご覧ください。
      .row.justify-content-md-center
        .col-12.col-md-8
          .card#function
            .card-body
              h5.card-title.text-left 機能について
              p.card-text.text-left.question 岩場の探し方が知りたい
              p.card-text.text-left.answer ヘッダーの[地図から探す]または[岩場を探す]をクリックすると検索画面が表示され、岩場を検索できます。
              p.card-text.text-left.question チェックインの方法が知りたい
              p.card-text.text-left.answer
                <b-link to="/info/guide_record_cehckin_process">こちら</b-link>をご参照ください
              p.card-text.text-left.question 記録の作成方法が知りたい
              p.card-text.text-left.answer
                <b-link to="/info/guide_record_cehckin_process">こちら</b-link>をご参照ください
              p.card-text.text-left.question 記録作成時に登録できる区分について知りたい
              p.card-text.text-left.answer
                |以下の区分を設定できます。<br>
                .row-box
                  .term 完登
                  .explanation ルートを登りきること
                .row-box
                  .term チャレンジ
                  .explanation 完登前のチャレンジ中の状態
                .row-box
                  .term オンサイト(一撃)
                  .explanation ルートを初見(ルート情報を持たない)で、完登すること
                .row-box
                  .term フラッシュ
                  .explanation 他人のムーブ(登る様子)を見ず、完登すること
              p.card-text.text-left.question ステータスバッジとは何ですか？
              p.card-text.text-left.answer 有料プランでチェックイン記録や登攀記録を作成頂くと、記録の数やルートの難易度に応じて付与されるキャラクターのバッジです。 
              p.card-text.text-left.question チェックインポイントとは何ですか？
              p.card-text.text-left.answer 岩場でチェックインすると必ず獲得できるポイントです。マイページの[チェックインラリー]タブで現在の獲得ポイントを確認できます。
              p.card-text.text-left.question チェックインラリーとは何ですか？
              p.card-text.text-left.answer エリアや都道府県別などのチェックイン達成度を集計したものです。外岩巡りの楽しみの１つとしてご利用ください。
      .row.justify-content-md-center
        .col-12.col-md-8
          .card#premium
            .card-body
              h5.card-title.text-left 有料プランについて
              p.card-text.text-left.question 利用料について知りたい
              p.card-text.text-left.answer 利用料は月額300円(税込)です。引き落としはクレジットカードと携帯キャリア決済からご選択頂けます。
                <b-link to="/info/law">こちら</b-link>もご覧ください。
              p.card-text.text-left.question 有料会員になるとできることを知りたい
              p.card-text.text-left.answer 
                <b-link to="/info/user_guide">こちら</b-link>をご参照ください
              p.card-text.text-left.question ヤマラボを退会したい
              p.card-text.text-left.answer 「アカウント設定」の[情報の確認・修正]を選択し、画面下部の「ヤマラボを退会する」リンクからお手続きください。
      .row.justify-content-md-center
        .col-12.col-md-8
          .card#inquiry
            .card-body
              h5.card-title.text-left その他<small>（情報提供や問合せ）</small>
              p.card-text.text-left.question 岩場の情報や写真を提供したい
              p.card-text.text-left.answer 
                |是非<b-link to="/feed_back/input">こちら</b-link>より情報をお寄せください。弊社で確認後、サイトに掲載させて頂く場合がございます。
              p.card-text.text-left.question 掲載している情報の誤りやバグを見つけた
              p.card-text.text-left.answer
                <b-link to="/feed_back/input">こちら</b-link>よりお知らせください。
              p.card-text.text-left.question その他、問合せをしたい
              p.card-text.text-left.answer 
                <b-link to="/inquiry_form/input">こちら</b-link>よりお問い合わせください。

    .Page-Btn(@click="scrollTop()")
      i.fas.fa-chevron-up.Page-Btn-Icon
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    scrollTop(){
      window.scrollTo({
        top: 0,
        //動きが見える移動
        behavior: "smooth"
        //ヒュンと移動
        //behavior: "instant"
      });
    },
    scrollCard(target){
      window.scrollTo({
        top: document.getElementById(target).getBoundingClientRect().top + window.pageYOffset - 60,
        //動きが見える移動
        behavior: "smooth"
      });
    }
  },
  components: {
  },
}
</script>

<style lang="stylus" scoped>
.container-fluid
  background #eceeef
  margin-left 0px
  margin-right 0px  
  .container
    .header
      padding 2rem 0
      font-size 5vw
      font-weight 700
      color #374047
    .row
      .col-12.col-md-8
        padding 0px
        .card
          margin 1rem 0
          .card-body
            .card-title
              font-weight 800
            .question
              padding 0.3rem 1rem
              font-weight 600
              color #374047
              background #e2eff9
            .answer
              padding 0 1rem
              font-size 1rem
            .row-box
              width 100%
              .term
                font-weight 700
                padding-left 2rem
              .explanation
                padding-left 3rem             
            .question:before
              content: "Q."
              font-weight bold
              margin-right 5px
            .answer:before
              content "A."
              font-weight bold
              margin-right 5px

              
  //ページTOPに戻るボタン
  .Page-Btn 
    position fixed
    right 1.5rem
    bottom 1.5rem
    width 32px
    height 32px
    line-height 32px
    text-align center
    border-radius 50%
    background #17a2b8
  .Page-Btn-Icon 
    color #fff
    font-size 16px

@media (min-width 992px) 
  .container-fluid
    .container
      .row
        .col-12.col-md-8
          padding 0 15px 
      .header
        font-size 1.5rem

</style>
