var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "container" }, [
      _vm._m(0),
      _c("div", { staticClass: "row justify-content-md-center navi-row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-4" },
          [
            _c(
              "b-button",
              {
                staticClass: "faq",
                attrs: { block: "", variant: "outline-info", size: "sm" },
                on: {
                  click: function($event) {
                    return _vm.scrollCard("about")
                  }
                }
              },
              [_vm._v("ヤマラボについて")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-md-4" },
          [
            _c(
              "b-button",
              {
                staticClass: "faq",
                attrs: { block: "", variant: "outline-info", size: "sm" },
                on: {
                  click: function($event) {
                    return _vm.scrollCard("function")
                  }
                }
              },
              [_vm._v("機能について")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row justify-content-md-center navi-row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-4" },
          [
            _c(
              "b-button",
              {
                staticClass: "faq",
                attrs: { block: "", variant: "outline-info", size: "sm" },
                on: {
                  click: function($event) {
                    return _vm.scrollCard("premium")
                  }
                }
              },
              [_vm._v("有料プランについて")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-md-4" },
          [
            _c(
              "b-button",
              {
                staticClass: "faq",
                attrs: { block: "", variant: "outline-info", size: "sm" },
                on: {
                  click: function($event) {
                    return _vm.scrollCard("inquiry")
                  }
                }
              },
              [_vm._v("その他")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-12 col-md-8" }, [
          _c("div", { staticClass: "card", attrs: { id: "about" } }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h5", { staticClass: "card-title text-left" }, [
                _vm._v("ヤマラボ(YamaLab.)について")
              ]),
              _c("p", { staticClass: "card-text text-left question" }, [
                _vm._v("ヤマラボとは何ですか？")
              ]),
              _c(
                "p",
                { staticClass: "card-text text-left answer" },
                [
                  _vm._v("ヤマラボは"),
                  _c("b-link", { attrs: { to: "/info/company" } }, [
                    _vm._v("株式会社ロビンソン")
                  ]),
                  _vm._v("が運営する外岩クライミング情報サイトです。")
                ],
                1
              ),
              _c("p", { staticClass: "card-text text-left question" }, [
                _vm._v("ヤマラボはアプリですか？")
              ]),
              _c("p", { staticClass: "card-text text-left answer" }, [
                _vm._v(
                  "ヤマラボはスマートフォンアプリではございません。PC、タブレット、スマートフォン端末のWEBブラウザからご利用いただけます。"
                )
              ]),
              _c("p", { staticClass: "card-text text-left question" }, [
                _vm._v("無料で使えますか？")
              ]),
              _c(
                "p",
                { staticClass: "card-text text-left answer" },
                [
                  _vm._v(
                    "ユーザー登録なしで全国の約200岩場を検索できます。有料プランにご契約頂きますと「岩場のルート一覧」や「画像トポ」の参照、「記録の作成」が可能になります。詳しくは"
                  ),
                  _c("b-link", { attrs: { to: "/info/user_guide" } }, [
                    _vm._v("こちら")
                  ]),
                  _vm._v("もご覧ください。")
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-12 col-md-8" }, [
          _c("div", { staticClass: "card", attrs: { id: "function" } }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h5", { staticClass: "card-title text-left" }, [
                _vm._v("機能について")
              ]),
              _c("p", { staticClass: "card-text text-left question" }, [
                _vm._v("岩場の探し方が知りたい")
              ]),
              _c("p", { staticClass: "card-text text-left answer" }, [
                _vm._v(
                  "ヘッダーの[地図から探す]または[岩場を探す]をクリックすると検索画面が表示され、岩場を検索できます。"
                )
              ]),
              _c("p", { staticClass: "card-text text-left question" }, [
                _vm._v("チェックインの方法が知りたい")
              ]),
              _c(
                "p",
                { staticClass: "card-text text-left answer" },
                [
                  _c(
                    "b-link",
                    { attrs: { to: "/info/guide_record_cehckin_process" } },
                    [_vm._v("こちら")]
                  ),
                  _vm._v("をご参照ください")
                ],
                1
              ),
              _c("p", { staticClass: "card-text text-left question" }, [
                _vm._v("記録の作成方法が知りたい")
              ]),
              _c(
                "p",
                { staticClass: "card-text text-left answer" },
                [
                  _c(
                    "b-link",
                    { attrs: { to: "/info/guide_record_cehckin_process" } },
                    [_vm._v("こちら")]
                  ),
                  _vm._v("をご参照ください")
                ],
                1
              ),
              _c("p", { staticClass: "card-text text-left question" }, [
                _vm._v("記録作成時に登録できる区分について知りたい")
              ]),
              _vm._m(1),
              _vm._m(2),
              _vm._m(3),
              _vm._m(4),
              _vm._m(5),
              _c("p"),
              _c("p", { staticClass: "card-text text-left question" }, [
                _vm._v("ステータスバッジとは何ですか？")
              ]),
              _c("p", { staticClass: "card-text text-left answer" }, [
                _vm._v(
                  "有料プランでチェックイン記録や登攀記録を作成頂くと、記録の数やルートの難易度に応じて付与されるキャラクターのバッジです。 "
                )
              ]),
              _c("p", { staticClass: "card-text text-left question" }, [
                _vm._v("チェックインポイントとは何ですか？")
              ]),
              _c("p", { staticClass: "card-text text-left answer" }, [
                _vm._v(
                  "岩場でチェックインすると必ず獲得できるポイントです。マイページの[チェックインラリー]タブで現在の獲得ポイントを確認できます。"
                )
              ]),
              _c("p", { staticClass: "card-text text-left question" }, [
                _vm._v("チェックインラリーとは何ですか？")
              ]),
              _c("p", { staticClass: "card-text text-left answer" }, [
                _vm._v(
                  "エリアや都道府県別などのチェックイン達成度を集計したものです。外岩巡りの楽しみの１つとしてご利用ください。"
                )
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-12 col-md-8" }, [
          _c("div", { staticClass: "card", attrs: { id: "premium" } }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h5", { staticClass: "card-title text-left" }, [
                _vm._v("有料プランについて")
              ]),
              _c("p", { staticClass: "card-text text-left question" }, [
                _vm._v("利用料について知りたい")
              ]),
              _c(
                "p",
                { staticClass: "card-text text-left answer" },
                [
                  _vm._v(
                    "利用料は月額300円(税込)です。引き落としはクレジットカードと携帯キャリア決済からご選択頂けます。"
                  ),
                  _c("b-link", { attrs: { to: "/info/law" } }, [
                    _vm._v("こちら")
                  ]),
                  _vm._v("もご覧ください。")
                ],
                1
              ),
              _c("p", { staticClass: "card-text text-left question" }, [
                _vm._v("有料会員になるとできることを知りたい")
              ]),
              _c(
                "p",
                { staticClass: "card-text text-left answer" },
                [
                  _c("b-link", { attrs: { to: "/info/user_guide" } }, [
                    _vm._v("こちら")
                  ]),
                  _vm._v("をご参照ください")
                ],
                1
              ),
              _c("p", { staticClass: "card-text text-left question" }, [
                _vm._v("ヤマラボを退会したい")
              ]),
              _c("p", { staticClass: "card-text text-left answer" }, [
                _vm._v(
                  "「アカウント設定」の[情報の確認・修正]を選択し、画面下部の「ヤマラボを退会する」リンクからお手続きください。"
                )
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-12 col-md-8" }, [
          _c("div", { staticClass: "card", attrs: { id: "inquiry" } }, [
            _c("div", { staticClass: "card-body" }, [
              _vm._m(6),
              _c("p", { staticClass: "card-text text-left question" }, [
                _vm._v("岩場の情報や写真を提供したい")
              ]),
              _c(
                "p",
                { staticClass: "card-text text-left answer" },
                [
                  _vm._v(" 是非"),
                  _c("b-link", { attrs: { to: "/feed_back/input" } }, [
                    _vm._v("こちら")
                  ]),
                  _vm._v(
                    "より情報をお寄せください。弊社で確認後、サイトに掲載させて頂く場合がございます。"
                  )
                ],
                1
              ),
              _c("p", { staticClass: "card-text text-left question" }, [
                _vm._v("掲載している情報の誤りやバグを見つけた")
              ]),
              _c(
                "p",
                { staticClass: "card-text text-left answer" },
                [
                  _c("b-link", { attrs: { to: "/feed_back/input" } }, [
                    _vm._v("こちら")
                  ]),
                  _vm._v("よりお知らせください。")
                ],
                1
              ),
              _c("p", { staticClass: "card-text text-left question" }, [
                _vm._v("その他、問合せをしたい")
              ]),
              _c(
                "p",
                { staticClass: "card-text text-left answer" },
                [
                  _c("b-link", { attrs: { to: "/inquiry_form/input" } }, [
                    _vm._v("こちら")
                  ]),
                  _vm._v("よりお問い合わせください。")
                ],
                1
              )
            ])
          ])
        ])
      ])
    ]),
    _c(
      "div",
      {
        staticClass: "Page-Btn",
        on: {
          click: function($event) {
            return _vm.scrollTop()
          }
        }
      },
      [_c("i", { staticClass: "fas fa-chevron-up Page-Btn-Icon" })]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header text-center" }, [
      _vm._v("FAQ"),
      _c("br"),
      _c("small", [_vm._v("よくあるご質問")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text text-left answer" }, [
      _vm._v("以下の区分を設定できます。"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-box" }, [
      _c("div", { staticClass: "term" }, [_vm._v("完登")]),
      _c("div", { staticClass: "explanation" }, [
        _vm._v("ルートを登りきること")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-box" }, [
      _c("div", { staticClass: "term" }, [_vm._v("チャレンジ")]),
      _c("div", { staticClass: "explanation" }, [
        _vm._v("完登前のチャレンジ中の状態")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-box" }, [
      _c("div", { staticClass: "term" }, [_vm._v("オンサイト(一撃)")]),
      _c("div", { staticClass: "explanation" }, [
        _vm._v("ルートを初見(ルート情報を持たない)で、完登すること")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-box" }, [
      _c("div", { staticClass: "term" }, [_vm._v("フラッシュ")]),
      _c("div", { staticClass: "explanation" }, [
        _vm._v("他人のムーブ(登る様子)を見ず、完登すること")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "card-title text-left" }, [
      _vm._v("その他"),
      _c("small", [_vm._v("（情報提供や問合せ）")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }